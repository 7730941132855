.service-gradient-border {
    position: relative;
}

.service-gradient-border::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(to right, #17CE8C, #CDF38E); /* Define your gradient colors */
}

.foodBorderGradient {
    border: 2px solid transparent;
    background: linear-gradient(to right, white, white) padding-box, linear-gradient(to right, #17CE8C, #17CE8C) border-box;
}

.entertainmentVenuesBorderGradient {
    border: 2px solid transparent;
    background: linear-gradient(to right, white, white) padding-box, linear-gradient(to right, #F4D35E, #F4D35E) border-box;
}

.accommodationBorderGradient {
    border: 2px solid transparent;
    background: linear-gradient(to right, white, white) padding-box, linear-gradient(to right, #6DDFE2, #6DDFE2) border-box;
}

.retailBorderGradient {
    border: 2px solid transparent;
    background: linear-gradient(to right, white, white) padding-box, linear-gradient(to right, #E14848, #E14848) border-box;
}