.customhub-bg {
  background: linear-gradient(180deg, rgba(242, 224, 214, 0) 0%, #f9f7f7 100%);
}
.customhub-card-shadow {
  box-shadow: 0px 0px 21px 19px rgba(175, 175, 175, 0.07);
}

.customerhub .title {
  background: linear-gradient(to right, #240B3B, #3D1E59, #8319B4, #BC14FE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.customerhub .feature-item {
  border-bottom: 2px solid transparent;
  background: linear-gradient(to right, white, white) padding-box, linear-gradient(to right, #240B3B, #3D1E59, #8319B4, #BC14FE) border-box;
}