@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.custom-table {
  border-spacing: 0px;
  min-width: 1024px;
  display: table;
  overflow-x: auto;
  white-space: nowrap;
}

.cart-align-col-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-layout {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.theader td {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #4d4d4d;
}

.table-title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #131118;
}

.table-description {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #131118;
}

.text-price {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #131118;
}

.rectangle {
  box-sizing: border-box;
  height: 44px;
  border: 1px solid #131118;
  border-radius: 10px;
}

/* CART */

.cart-border {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 20px;
}

.cart-border .title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #1a1a1a;
}

.cart-border .line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  background: #ffffff;
  box-shadow: 0px 1px 0px #e6e6e6;
}

.line-value {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #1a1a1a;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.line-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #4d4d4d;
}

.total-label {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: #1a1a1a;
}

.proceed {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background: #131118;
  border-radius: 10px;
}

.enter-code {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #4d4d4d;
}

.enter-code-input {
  box-sizing: border-box;
  border: 1px solid #131118;
  border-radius: 10px;
  height: 56px;
  padding-inline: 10px;
}

.enter-code-input button {
  box-sizing: border-box;
  background: #131118;
  border: 1px solid #131118;
  border-radius: 10px;
  height: 56px;
}

.proceed span,
.enter-code-input button span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  flex-grow: 0;
}

.stepper-label {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #131118;
}

@media (max-width: 576px) {
  .cart-align-col-middle {
    width: 100%;
  }
}
