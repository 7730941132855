
.link-button{
    font-weight: 500;
  font-size: 18px;
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.features-food.tabs-container .tab.is-active,
.accommodation-features.tabs-container .tab.is-active,
.venues-features.tabs-container .tab.is-active,
.retail-features.tabs-container .tab.is-active{
    color: white;
}
.features-food.wws-learn-more,
.features-food.tabs-container .tab.is-active,
.features-food.tabs-content .circle-tick{
background: linear-gradient(90deg, #17CE8C 0%, #17CE8C 100%);
}
.features-food .link-button{
    background-image: linear-gradient(90deg, #17CE8C 0%, #17CE8C 100%);
}
.accommodation-features.wws-learn-more,
.accommodation-features.tabs-container .tab.is-active,
.accommodation-features.tabs-content .circle-tick{
    background: linear-gradient(90deg, #6DDFE2 0%, #6DDFE2 100%);
}
.accommodation-features .link-button{
    background-image: linear-gradient(90deg, #6DDFE2 0%, #6DDFE2 100%);
}

.venues-features.wws-learn-more,
.venues-features.tabs-container .tab.is-active,
.venues-features.tabs-content .circle-tick{
    background: linear-gradient(90deg, #F4D35E 0%, #F4D35E 100%);
}

.venues-features .link-button{
    background-image: linear-gradient(90deg, #F4D35E 0%, #F4D35E 100%);
}

.retail-features.wws-learn-more,
.retail-features.tabs-container .tab.is-active,
.retail-features.tabs-content .circle-tick{
    background: linear-gradient(90deg, #E14848 0%, #E14848 100%);
}

.retail-features .link-button{
    background-image: linear-gradient(90deg, #E14848 0%, #E14848 100%);
}


