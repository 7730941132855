.blog-carousel .react-multiple-carousel__arrow{
    background-color: white !important;
    border: 1px solid #E0E0E0;
    border-radius: 50%;
    
}
/* .blog-carousel .react-multiple-carousel__arrow--right{
    right: 0px !important;
}

.blog-carousel .react-multiple-carousel__arrow--left{
    left: 0px !important;
} */
.blog-carousel-wrapper {
    position: relative;
}
.blog-carousel{
    position: unset;
}
.blog-carousel .react-multiple-carousel__arrow {
        position:absolute;
    }

.blog-carousel .react-multiple-carousel__arrow--left {
        left: calc(-2% + 1px)
    }

.blog-carousel .react-multiple-carousel__arrow--right {
        right: calc(-2% + 1px)
    }
.blog-pagination .ant-pagination-item{
    background-color: white !important;
    color: black ;
    border: none;
    border-radius: 6px;
}
.blog-pagination .ant-pagination-item-active{
    background: linear-gradient(to right, #240B3B, #3D1E59, #8319B4, #BC14FE);
    color: white !important;
    border: none;
    border-radius: 6px;
}
.blog-pagination .ant-pagination-item-active a{

    color: white !important;

}
.blog-pagination .ant-pagination-next{
    display: none !important;
}
.blog-pagination .ant-pagination-prev{
    display: none !important;
}
@media only screen and (max-width:640px) {

    
.blog-carousel .react-multiple-carousel__arrow--left {
        left: 15px;
        padding:5px;
    }

.blog-carousel .react-multiple-carousel__arrow--right {
        right: 15px;
        padding:5px;

    }
}

.blog-read-more {
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
}