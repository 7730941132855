.custom-carousel-about .react-multi-carousel-item--active~.react-multi-carousel-item--active .out-div{
    width: 431px !important;
    height: 361px !important;
    background: linear-gradient(to right, #280B3B, #5D1C82, #BC14FE);
    opacity: 1;
}

.custom-carousel-about .react-multi-carousel-item--active~.react-multi-carousel-item--active~.react-multi-carousel-item--active  .out-div
{
    width: 396px !important;
    height: 332px !important;
    background-color: white !important;
    background: none;
    opacity: 1;
}


.custom-carousel-about ul li {
    display: flex;
    justify-content: center;
    align-items: center;
}



.custom-carousel-about .react-multiple-carousel__arrow--left
{
    left: unset;
    right: calc(100% - 20%);
}

.custom-carousel-about .react-multiple-carousel__arrow--right 
{
    right: unset;
    left: calc(100% - 20%);
}

@media (min-width: 768px ) {
    .custom-carousel-about .react-multiple-carousel__arrow--left
    {
        left: unset;
        right: calc(100% - 33%);
    }

    .custom-carousel-about .react-multiple-carousel__arrow--right 
    {
        right: unset;
        left: calc(100% - 33%);
    }

    .custom-carousel-about .react-multi-carousel-item--active~.react-multi-carousel-item--active~.react-multi-carousel-item--active  .out-div, 
    .custom-carousel-about .react-multi-carousel-item .out-div
    {
        opacity: 0.2;
    }
}

@media (min-width: 1536px ) {
    .custom-carousel-about .react-multiple-carousel__arrow--left
    {
        left: unset;
        right: calc(100% - 35%);
    }

    .custom-carousel-about .react-multiple-carousel__arrow--right 
    {
        right: unset;
        left: calc(100% - 35%);
    }
}

.custom-carousel-about .item-shadow {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}