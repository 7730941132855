.scrollToUp { 
    background-image: url('../../assets/svgs/scroll-up.svg');
}

.subscribe-btn-trigger {
    padding: 8px;
    color: white;
    display: block;
    white-space: nowrap;
    max-width: 380px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subscribe-btn-trigger-container {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
    height: 60px;
    min-width: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 16px -2px;
    border-radius: 999px;
    transition: opacity 0.3s ease;
    padding: 0px 20px 0 20px;
}