@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&family=Yellix:wght@600;700&display=swap");

.active-product-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: #17ce8c;
  border-radius: 16px;
}

.bg-custom-green {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  background: #17ce8c;
  border-radius: 16px;
  width: 100%;
}

.rounded-number {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
}

.plus-minus-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  background: #f6f6f9;
  border-radius: 16px;
}

.confirm-order {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  height: 54px;
  background: #17ce8c;
  border-radius: 16px;
}

.add-to-order {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  background: #17ce8c;
  border-radius: 16px;
}

/* Checkbox */
.custom-checkbox {
  appearance: none;
  background-color: #fff;
  border: 2px solid #aaaaaa;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked::before {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 60%;
  background-color: #17ce8c;
}

.component-header {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.component-header-line {
  width: 60px;
  height: 4px;
  background-color: #c2c2d6;
  border-radius: 2px;
}

.bg-white-order {
  display: flex;
  padding: 16px 24px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(12, 26, 75, 0.02),
    0px -10px 20px rgba(220, 220, 228, 0.3);
  border-radius: 26px 26px 0px 0px;
  width: 100%;
}

.product-content-bg {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #ffffff;
  border-radius: 16px;
}

.en-text-bg {
  background: white;
}

.en-text {
  font-size: 16px;
  line-height: 20px;
  color: #666687;
}

.success-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 21px;
  background: #f6f6f9;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.03),
    0px 4px 20px -2px rgba(50, 50, 71, 0.04);
  border-radius: 16px;
}

.success-product-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px 16px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.03),
    0px 4px 20px -2px rgba(50, 50, 71, 0.04);
  border-radius: 16px;
}

.success-product {
  font-family: "Yellix";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #8e8ea9;
}

.currency-product {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #8e8ea9;
}

/* Product cards */
.language-products {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 7px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(12, 26, 75, 0.05),
    0px 4px 20px -2px rgba(50, 50, 71, 0.02);
  border-radius: 12px;
  flex-grow: 0;
}

@media (max-width: 768px) {
  .bg-white-order {
    margin-bottom: env(safe-area-inset-bottom, 20px) !important;
  }
}

.product-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align-items: center;*/
  gap: 12px;
  background: #ffffff;
  /*box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.03),*/
  /*  0px 4px 20px -2px rgba(50, 50, 71, 0.04);*/
  border-radius: 16px;
}

.send-order-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.label-product-details-description {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  color: #666687;
}

.product-details-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  color: #666687;
}

.product-details-title {
  font-family: "Yellix";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #666687;
}

.product-volume {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;

  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.03),
    0px 4px 20px -2px rgba(50, 50, 71, 0.04);
  border-radius: 16px;
  flex-grow: 1;
}

.product-add-to-order {
  padding: 16px 24px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(12, 26, 75, 0.02),
    0px -10px 20px rgba(220, 220, 228, 0.3);
  border-radius: 26px 26px 0px 0px;
  width: 100%;
}

.notes-block-bb-bar {
  border-radius: 16px;
}
