@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.intro p {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #131118;
}

.address {
  background: rgba(164, 161, 170, 0.05);
  border-radius: 10px;
  padding: 10px;
}

.address .name {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #131118;
}

.address .default-address {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #131118;
}

.span {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #131118;
}

.address .edit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 141px;
  height: 34px;
  background: rgba(164, 161, 170, 0.1);
  border-radius: 8px;
}

.address .delete {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 141px;
  height: 34px;

  background: rgba(225, 72, 72, 0.1);
  border-radius: 8px;
}

.deliver {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  width: 320px;
  height: 56px;
  background: #131118;
  border-radius: 10px;
}

.deliver span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
}

.separator {
  height: 1px;
  border: 1px solid rgba(164, 161, 170, 0.2);
}

.shipping-form {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 10px;
}

.shipping-form h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #131118;
}

.shipping-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* gap: 8px; */
}

.payment-title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #131118;
}

.payment-label {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #131118;
}

.save-card-label {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #131118;
}

/* REVIEW */

.review h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #131118;
}

.review .address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  background-color: white;
}

.review .address h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #131118;
}

.review .address .description {
  display: flex;
  padding: 0px;
  gap: 20px;
}

.review .address .description h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #131118;
}

.review .address .description span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #131118;
}

.payment-review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.payment-review h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #131118;
}

.payment-review .description {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 20px;
}

.payment-review .description p {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #131118;
}

.order-confirmed {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #131118;
}

.order-description {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #131118;
}

.back-btn {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;

  background: #ffffff;
  border: 1px solid #131118;
  border-radius: 10px;
}
