.video-cover {

    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI2NjUiIHZpZXdCb3g9IjAgMCAxNDQwIDY2NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjE1ODAiIGhlaWdodD0iNjY3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzEgLTIuMzM5ODQpIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNNjYuNSAxMDUuMTZMNjYuNSA1MzguOTY4TDI4OC41OTYgNTM4Ljk2OEM0MDguMzg5IDUzOC45NjggNTA1LjUgNDQxLjg1NyA1MDUuNSAzMjIuMDY0QzUwNS41IDIwMi4yNzEgNDA4LjM4OSAxMDUuMTYgMjg4LjU5NiAxMDUuMTZMNjYuNSAxMDUuMTZaIiBzdHJva2U9IiM4QTk1QTYiLz4KPHBhdGggZD0iTTcxOSA0MjcuNDc5Qzc3NS45NzggNDI3LjQ3OSA4MjIuMTUxIDM4MC4zMTYgODIyLjE1MSAzMjIuMTZDODIyLjE1MSAyNjQuMDA0IDc3NS45NzggMjE2Ljg0MSA3MTkgMjE2Ljg0MUM2NjIuMDIyIDIxNi44NDEgNjE1Ljg0OSAyNjQuMDA0IDYxNS44NDkgMzIyLjE2QzYxNS44NDkgMzgwLjMxNiA2NjIuMDIyIDQyNy40NzkgNzE5IDQyNy40NzlaTTkzMS41IDMyMi4xNkM5MzEuNSA0NDIuMDE2IDgzNi4zNTEgNTM5LjE2IDcxOSA1MzkuMTZDNjAxLjY0OSA1MzkuMTYgNTA2LjUgNDQyLjAxNiA1MDYuNSAzMjIuMTZDNTA2LjUgMjAyLjMwNCA2MDEuNjQ5IDEwNS4xNiA3MTkgMTA1LjE2QzgzNi4zNTEgMTA1LjE2IDkzMS41IDIwMi4zMDQgOTMxLjUgMzIyLjE2WiIgc3Ryb2tlPSIjOEE5NUE2Ii8+CjxwYXRoIGQ9Ik0xMTQ5IDMzMS4xNlY1NDguMTZDMTAyOS4zOCA1NDcuODkgOTMyLjUgNDUwLjgzOSA5MzIuNSAzMzEuMTZDOTMyLjUgMjExLjQ4MSAxMDI5LjM4IDExNC40MyAxMTQ5IDExNC4xNjFWMzMxLjE2WiIgc3Ryb2tlPSIjOEE5NUE2Ii8+CjxwYXRoIGQ9Ik0xMzY2IDMzMS4xNlY1NDguMTZDMTI0Ni4zOCA1NDcuODkgMTE0OS41IDQ1MC44MzkgMTE0OS41IDMzMS4xNkMxMTQ5LjUgMjExLjQ4MSAxMjQ2LjM4IDExNC40MyAxMzY2IDExNC4xNjFWMzMxLjE2WiIgc3Ryb2tlPSIjOEE5NUE2Ii8+Cjwvc3ZnPgo=")
}
.boxShadow:hover{
    box-shadow: 0px -2px 23px 0px rgba(46, 39, 59, 0.26);

}

/* Initially hide all tab content with a fade-out effect */
.tabs-content .tab-content__panel {
    display: none;
    opacity: 0;
}

/* Show the active tab content with a fade-in effect using CSS transitions */
.tabs-content .tab-content__panel.is-active {
    display: block;
    transition: opacity 0.3s ease-in-out; /* Adjust the duration and timing function as needed */
    opacity: 1;
}

.tabs-container {
    background: #f7f7f7;
    padding: 8px 30px;
    border-radius: 40px;
    width: 100%;
}

.tabs-container .tab.is-active {
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
    border-radius: 40px;
    color: #fff;
}

.tabs-container .tab.is-active {
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
    color: #fff;
}


/* Style individual tab buttons on mobile */
@media (max-width: 768px) {
    button.tab {
        margin-bottom: 15px;
        background: #f7f7f7;
        width: 100%;
        border-radius: 40px;
    }

    .tabs-container {
        background: transparent;
        padding: 0;
        margin-bottom: 20px;
    }
}

.wws-learn-more {
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
}

.food-industry .circle-tick{
    background: #17CE8C;
}
.food-industry .linkbutton{
    font-weight: 500;
    font-size: 18px;
    color: #17CE8C;
}