.dropdownnewmenu{
    width: 90%;
    left: 5%;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06), 0 3px 6px -4px rgba(0, 0, 0, 0.09), 0 9px 28px 8px rgba(0, 0, 0, 0.08);
}


.item-hover-transform {
    transform: rotate(180deg);
    transition: transform 0.5s ease;

}

.item-not-hover-transform {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }

.item-hover-bg{
    /**background: rgba(217, 217, 217, 0.25);**/
    background: linear-gradient(90deg, rgba(120, 196, 61, 0.2) 0%, rgba(205, 243, 142, 0.2) 100%);
}
.overview-more{
    border: 1px solid;
}
.food-overview .overview-more{
    border-color: #17CE8C;
}
.entertainment-overview .overview-more{
    border-color: #F4D35E;
}
.accommodation-overview .overview-more{
    border-color: #6DDFE2;
}
.retail-overview .overview-more{
    border-color: #E14848;
}