.share__link:active,
.share__link:hover {
  /* --shadow-opacity: .2; */
  /* color: #7b68ee; */
  /* color: #BC13FE; */
  cursor: pointer;
  -webkit-transform: translate3d(0, 2px, 0);
  transform: translate3d(0, 2px, 0);
}

.hd-shadow {
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);*/
  /*!*background: #fff;*!*/
  /*border-radius: 9.01455px;*/
  /*-webkit-box-shadow: 0 13.5px 64px hsla(0,0%,40%,.1);*/
  /*padding: 10px;*/
  /*font-weight: 600;*/
  /*-webkit-transition: -webkit-transform var(--transition-short);*/
  /*transition: -webkit-transform var(--transition-short);*/
  /*-o-transition: transform var(--transition-short);*/
  /*transition: transform var(--transition-short);*/
  /*transition: transform var(--transition-short),-webkit-transform var(--transition-short);*/
  /*-webkit-transition-delay: 50ms;*/
  /*-o-transition-delay: 50ms;*/
  /*transition-delay: 50ms;*/
}

.article__img {
  border-radius: 9px;
  -webkit-box-shadow: 0 10px 40px rgba(16, 30, 54, 0.2);
  box-shadow: 0 10px 40px rgba(16, 30, 54, 0.2);
}

.article__icon {
  background-color: rgb(73, 204, 249);
}

.swiper-dots {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 2rem;
}

.MuiBadge-dot {
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(47, 43, 61, 0.26);
}

.swiper-dots .MuiBadge-root:not(:last-child) {
  margin-right: 1rem;
}

.swiper-dots .MuiBadge-root.active .MuiBadge-dot {
  background-color: rgb(115, 103, 240);
}

.ql-size-large {
  font-size: 18px !important;
  line-height: 28px !important;
  font-weight: bold !important;
  margin-top: 7px !important;
}

.code-block {
  background-color: "red";
}
 

.large-text {
  font-size: 28px !important;
  /* line-height: 32px !important; */
  font-weight: bold !important;
  color: black !important;
  margin-top: 28px !important;
  margin-bottom: 10px;
}
.custom-text {
  font-size: 16px !important;
  color: #666666 !important;
  line-height: 26px !important;
  font-weight: 400 !important;
}

.custom-quill .large-text {
  margin-top: 32px;
}

@media (min-width: 768px) {
  .large-text {
    font-size: 24px !important;
  }

  .custom-text {
    font-size: 18px !important;
    line-height: 32px !important;
  }
}


.custom-heading {
  font-weight: bold;
  color: #240B3B;
  margin-top: 28px;
  margin-bottom: 10px;
}

.custom-h1 {
  font-size: 28px;
  line-height: 1.2;
}

.custom-h2 {
  font-size: 26px;
  line-height: 1.25;
}

.custom-h3 {
  font-size: 24px;
  line-height: 1.3;
}

.custom-h4 {
  font-size: 22px;
  line-height: 1.35;
}

.custom-h5 {
  font-size: 20px;
  line-height: 1.4;
}

.custom-h6 {
  font-size: 18px;
  line-height: 1.45;
}

.custom-large-text {
  font-size: 28px;
  line-height: 1.3;
  font-weight: bold;
  color: #240B3B;
}

.custom-paragraph {
  font-size: 16px;
  color: #666666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.custom-list {
  padding-left: 20px;
  margin-bottom: 20px;
}

.custom-list-item {
  font-size: 16px;
  color: #666666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.custom-link {
  color: #5D1C82;
  text-decoration: underline;
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  font-weight: 500;
  position: relative;
}

.custom-link:hover,
.custom-link:focus {
  color: #BC14FE;
  /*text-decoration: none;*/
}

.custom-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  transition: transform 0.3s ease-out;
}

.custom-link:hover::after,
.custom-link:focus::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.custom-link:active {
  transform: translateY(2px);
}

.custom-link[target="_blank"]::after {
  content: "\2197";
  display: inline-block;
  margin-left: 5px;
  font-size: 0.8em;
  transition: transform 0.3s ease;
}

.custom-link[target="_blank"]:hover::after {
  transform: translate(2px, -2px);
}

@media (min-width: 768px) {
  .custom-h1 { font-size: 28px; }
  .custom-h2 { font-size: 26px; }
  .custom-h3 { font-size: 24px; }
  .custom-h4 { font-size: 22px; }
  .custom-h5 { font-size: 20px; }
  .custom-h6 { font-size: 18px; }

  .custom-large-text {
    font-size: 32px;
    line-height: 1.25;
  }

  .custom-paragraph,
  .custom-list-item {
    font-size: 18px;
    line-height: 1.7;
  }
}