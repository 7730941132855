@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap");

.product-details .title {
  font-family: "Manrope", sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #131118;
}

.product-details .badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;
  background: rgba(225, 72, 72, 0.1);
  border-radius: 4px;
}

.product-details .badge span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #e14848;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.product-details .description {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #131118;
}

.product-details .reviews {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #a4a2aa;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.product-details .price {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #131118;
}

.product-details .previous-price {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: line-through;
  color: #a4a2aa;
  flex-grow: 0;
}

.product-details .additional-info {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #131118;
}

.color {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #131118;
}

.color-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.color-box {
  border-radius: 4px;
}

.product-details .add-to-cart-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;

  background: #131118;
  border-radius: 10px;
}

.product-details .add-to-cart-layout span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.product-details .favorite {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  border: 1px solid #131118;
  border-radius: 10px;
}

.product-details .description-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  top: 757px;
}

.children1 span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #131118;
}

.related-products h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: #131118;
}
