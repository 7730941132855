.library-marker {
  /* border-radius: 6px;
  border: 2px solid transparent;
  border-image-slice: 1;
  border-image-source: linear-gradient(101.56deg, #240B3B 3.95%, #3D1E59 34%, #5D1C82 49%, #8319B4 67.14%, #BC14FE 94.28%); */

  background: linear-gradient(white, white) padding-box,
              linear-gradient(101.56deg, #240B3B 3.95%, #3D1E59 34%, #5D1C82 49%, #8319B4 67.14%, #BC14FE 94.28%);
              /* linear-gradient(101.56deg, #8319B4 3.95%, #5D1C82 34%, #3D1E59 49%, #240B3B 67.14%, #BC14FE 94.28%); */
  border-radius: 6px;
  border: 2px solid transparent;
}

.page-title {
  color: linear-gradient(101.56deg, #240B3B 3.95%, #3D1E59 34%, #8319B4 67.14%, #BC14FE 94.28%);
}

.guide-header {
  box-shadow: 16px 16px 80px 0px #00000026;
}

.guide-item {
  box-shadow: 0px 10.363049507141113px 31.089149475097656px 0px #101E361A;
}