.pp-bordered-table {
    width: 100%;
    border-collapse: collapse;
}

.pp-bordered-table th,
.pp-bordered-table td {
    border: 1px solid #594f4e;
    padding: 8px;
    text-align: left;
}
