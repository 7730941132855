/* Initially hide all tab content with a fade-out effect */
.tabs-content .tab-content__panel {
    display: none;
    opacity: 0;
}

/* Show the active tab content with a fade-in effect using CSS transitions */
.tabs-content .tab-content__panel.is-active {
    display: block;
    transition: opacity 0.3s ease-in-out; /* Adjust the duration and timing function as needed */
    opacity: 1;
}

.tabs-container {
    background: #f7f7f7;
    padding: 8px 30px;
    border-radius: 40px;
    width: 100%;
}

.tabs-container .tab.is-active {
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
    border-radius: 40px;
    color: #fff;
}

.tabs-container .tab.is-active {
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
    color: #fff;
}

.wws-learn-more {
    background: linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%);
}



/* Style individual tab buttons on mobile */
@media (max-width: 768px) {
    button.tab {
        margin-bottom: 15px;
        background: #f7f7f7;
        width: 100%;
        border-radius: 40px;
    }

    .tabs-container {
        background: transparent;
        padding: 0;
        margin-bottom: 20px;
    }
}

.accom-industry .circle-tick{
    background: #6DDFE2;
}
.accom-industry .linkbutton{
    font-weight: 500;
    font-size: 18px;
    color: #6DDFE2;
}