/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Manrope'); */
*{
    font-family: 'Manrope', sans-serif;
}

@media screen and (min-width: 768px) {
    .freeJourneyModal .ant-modal-content .ant-modal-footer {
        display: flex !important;
    }
}