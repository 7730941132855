.get-started {
  background: linear-gradient(100.8deg, #240B3B 3.95%, #3D1E59 34%, #BC14FE 94.28%);
}


.get-started:hover {
  background: linear-gradient(100.8deg, #5b139d 3.95%, #3D1E59 34%, #8e3baf 94.28%);
}

.get-started:hover span {
  /*color: #5C1C81;*/
}

.ant-modal-transparent .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.ant-modal-transparent .ant-modal-wrap {
  background-color: rgba(0, 0, 0, 0.5) !important; /* Adjust opacity as needed */

}