.drawer-divider {
  width: 50px;
  height: 4px;
  background-color: #c0c0cf;
  border-radius: 2px;
  margin: 10px auto;
}

.drawer-header {
  flex: none;
  order: 0;
  flex-grow: 0;
}

.component {
  width: 100%;
  height: 40px;
  background-color: linear-gradient(
    90deg,
    #fcfcfc,
    #f7f7f7 10.04%,
    #f7f7f7 51.56%,
    #f7f7f7 89.58%,
    #fcfcfc
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.line {
  width: 50%;
  height: 4px;
  background-color: #c0c0cf;
  border-radius: 5px;
}
