.faq-search {
    width: 100%;
}

.faq-sidebar {
    width: 20%;
}

.faq-content {
    width: 80%;
}

.faq-search input {
    width: 70%;
}
.clip-img {
    /* width: 100%; */
    /* height: 100%; */
    clip-path: url(#blob);
    aspect-ratio: 332/284;

    /* overflow: hidden; */
}

.clip-img::after{
    background: var(--color-primary);
    opacity: 0.4;
    clip-path: none;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.clip-img img{
    object-fit: cover;
    /* aspect-ratio: 332/284; */

    /* position: absolute; */
}
.search{
    background-image: url("../../../assets/images/search-faq.png");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: calc(100% - 20px) 20px;
}
@media only screen and (max-width: 640px) {
    .search{
        background-size: 17px 17px;
        background-position: calc(100% - 10px) 15px;
    }   
}