.shadow-custom {
  box-shadow: 0px 6px 15px -2px #10182814;
}

.carousel-item {
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
