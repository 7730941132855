.lang-selector {
    z-index: 10000;
    width: 120px;
}

.lang-selector .lang-btn:after {
    content: '';
    transition: all 0.3s;
    border: solid #000;
    border-width: 0 2px 2px 0;
    float: right;
    margin-top: 0px;
    margin-right: 6px;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.lang-selector.active .lang-btn:after {
    margin-top: 6px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.lang-selector .ant-popover-inner {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}