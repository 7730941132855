.about-us-end {
    background-image: url('../../assets/images/about_us_bottom.png');
    height: 600px;
}

.about-us-end span {
    width: 65%;
}

@media (max-width: 576px) {
}
.aboutus-bg{
    background: linear-gradient(180deg, rgba(242, 224, 214, 0) 0%, #F9F7F7 100%);
}