.custom-carousel .react-multi-carousel-item--active~.react-multi-carousel-item--active  div{
    width: 400px !important;
    height: 400px !important;
    background-color: #240b3b !important;
}

.custom-carousel .react-multi-carousel-item--active~.react-multi-carousel-item--active  div img{
    /*width: 270px !important;*/
    /*height: 270px !important;*/

}
.custom-carousel .react-multi-carousel-item--active~.react-multi-carousel-item--active  div h2{
    color: white !important;
    font-size: 32px !important;
    transform: translateY(-28px) !important;
}

.custom-carousel .react-multi-carousel-item--active~.react-multi-carousel-item--active~.react-multi-carousel-item--active  div{
    width: 250px !important;
    height: 250px !important;
    background-color: #0000001A !important;
}

.custom-carousel .react-multi-carousel-item--active~.react-multi-carousel-item--active~.react-multi-carousel-item--active  div img{
    /*width: 200px !important;*/
    /*height: 200px !important;*/

}
.custom-carousel .react-multi-carousel-item--active~.react-multi-carousel-item--active~.react-multi-carousel-item--active  div h2{
    color: #000000D9 !important;
    font-size: 20px !important;
    transform: translateY(-20px) !important;

}

.custom-carousel  .react-multi-carousel-dot button{
    border: none !important;
    background-color: #D9D9D9 !important;
}
.custom-carousel  .react-multi-carousel-dot--active button{
    border: none !important;
    background: linear-gradient(128deg, #240b3b 3.95%, #3d1e59 34%, #33055E 67.3%, #bc14fe 94.28%)!important;
}

.custom-carousel ul li {
    display: flex;
    justify-content: center;
    align-items: center;
}