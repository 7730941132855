.email-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.subscribe-button {
    padding: 10px 20px;
    background-color: #240b3b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.subscribe-button:hover {
    background-color: #3d1e59;
}

.floating-wave-container {
    position: relative; /* Adjust the width as needed */
    /*height: 100px; !* Adjust the height as needed *!*/
    /*background-color: #yourBrandColor; !* Replace with your brand color *!*/
    /*border-radius: 10px; !* Adjust the border radius as needed *!*/
    /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
    overflow: hidden;
    padding-top: 15px;
}


.coming-soon-badge {
    position: absolute;
    top: 0px; /* Adjust the top position as needed */
    left: 10px; /* Adjust the right position as needed */
    background-color: #240b3b; /* Replace with your brand color */
    color: #fff; /* Text color */
    padding: 5px 10px;
    border-radius: 5px; /* Adjust the border radius as needed */
    font-size: 12px; /* Adjust the font size as needed */
}

@keyframes wave-animation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px); /* Adjust the wave's vertical movement */
    }
}

