@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap");

.filter-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #131118;
}

.offer-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14.0816px;
  gap: 7.04px;
  position: absolute;
  left: 9px;
  top: 12px;
  background: #e14848;
  border-radius: 7.04082px;
}

.offer-label {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 11.2653px;
  line-height: 15px;
  color: #ffffff;
}
.add-to-cart {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 10px;
  width: 222px;
  height: 50px;
}

.add-to-cart span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #131118;
}

.description-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
}

.description-section .title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 22px;
  color: #131118;
}

.description-section .description {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #131118;
}

.description-section .price {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #131118;
}

.description-section .previous-price {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: line-through;
  color: #a4a2aa;
  flex-grow: 0;
}

.view-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10%;
  top: 5%;
}

.pagination-cell {
  box-sizing: border-box;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #e14848;
  border-radius: 5px;
}

.pagination-cell span {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

/* left side */

.filter-layout .first-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

.filter-layout .title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #131118;
}

.price-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #131118;
}

.color-box {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.price-range {
  height: 14px;
}

.empty-state h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 23.7746px;
  line-height: 26px;

  color: #131118;
}

.empty-state span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16.6422px;
  line-height: 23px;
  color: #131118;
}

.search-badge {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  background: #fbfbfb;
  border: 1px solid #ebebeb;
  border-radius: 100px;
}

/* HOME */

.venue-home .venue-header {
  width: 100%;
  background-color: #1311180d;
}

.venue-home .product-name button span {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

/* shop category */

.shop-category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.shop-category .first-line {
  padding: 0px;
  gap: 30px;
}

.shop-category .first-line .icons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}
/* .shop-category .first-line p {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 44px;
  color: #131118;
} */

/* deals */

.deals {
  background-color: #1311180d;
  margin-top: 70px;
}

.deals h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 44px;
  color: #131118;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.deals p {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #131118;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.deals .timer {
  box-sizing: border-box;
  border: 1px solid rgba(164, 161, 170, 0.2);
  border-radius: 10px;
  padding: 10px;
}

.deals .timer div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.deals .timer div .title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #131118;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.deals .timer div .label {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #131118;
  flex: none;
  order: 1;
  flex-grow: 0;
}

/* FOOTER */

.footer {
  box-sizing: border-box;
  border-top: 1px solid #000000;
}

.footer .info-label {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1a1818;
  flex: none;
  order: 1;
  flex-grow: 0;
  max-width: 80%;
}

.footer .info-title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: #1a1818;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer .subscribe {
  box-sizing: border-box;
  border: 1px solid #1a1818;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 13px 16px;
}

.footer .divider {
  background: #e14848;
  height: 1px;
}

.footer .cards span {
  cursor: pointer;
}

.footer .right-reservation {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1a1818;
}

/* 1024 px */

@media (max-width: 1024px) {
  .venue-header {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 20px;
  }

  /* shop category */

  .shop-category {
    margin-top: 30px;
    padding: 0 10px;
  }

  .shop-category .first-line {
    /* flex-wrap: wrap; */
    justify-content: space-between;
  }

  /* .shop-category .first-line p {
    font-size: 22px;
    line-height: 36px;
    flex-basis: 100%;
    margin-bottom: 10px;
  } */

  .shop-category .first-line .icons {
    justify-content: flex-end;
    width: 100%;
  }

  .shop-category .first-line .icons span {
    font-size: 20px;
  }
}

/* 768 px */
@media (max-width: 768px) {
  .large-width {
    padding-inline: 10% !important;
  }

  .filter-layout .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
  }

  .venue-header {
    padding: 20px;
  }

  .venue-header > div {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  /* shopping category */

  .shop-category {
    margin-top: 30px;
    padding: 0 10px;
  }

  .shop-category .first-line {
    gap: 10px;
  }

  .shop-category .first-line p {
    font-size: 20px;
    line-height: 32px;
    width: 100%;
  }

  .shop-category .first-line .icons {
    justify-content: flex-end;
    width: 100%;
  }

  .shop-category .first-line .icons span {
    font-size: 20px;
  }

  .shop-category .first-line .icons {
    align-self: flex-end;
  }

  /* Footer */

  .footer {
    padding: 20px 10px;
  }

  .footer .info-label,
  .footer .info-title {
    font-size: 14px;
    line-height: 20px;
  }

  .footer .grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footer .right-reservation {
    text-align: center;
    order: 3;
  }

  .footer .cards {
    justify-content: center;
    order: 1;
  }

  .footer .cards:last-of-type {
    order: 2;
  }
}
