.home-carousel .react-multi-carousel-dot button {
  border-color: #E39DFF;
  background-color: #E39DFF;
}
.home-carousel .react-multi-carousel-dot--active button {
  border-color: #240B3B;
  background-color: #240B3B;
}
.home-carousel .react-multi-carousel-item {
  width: 668px;
}