.grow-content {
    background: linear-gradient(135.93deg, #240B3B 17.5%, #391060 96.26%);
}
.video-container {
    position: relative;
    padding-bottom: 62.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
