/* Accordion Styles */
.accordion {
    border: 1px solid #ccc;
}

.accordion-content {
    padding: 10px;
    display: none;
}

.accordion-content.active {
    display: block;
    color: #53534a;
}

/* Style the button within the accordion */
.accordion-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #EFEFEF; /* Change the background color on hover */
    color: #333; /* Change the text color on hover */
    border: none; /* Add this line to remove the border */
    border-radius: 0;
    padding: 10px 15px!important;
    height: 44px!important;
}

/* Style the button on hover (optional) */
.accordion-button:hover {
    background-color: #EFEFEF; /* Change the background color on hover */
    color: #333; /* Change the text color on hover */
}

/* Style the .card-header within the accordion */
.accordion .card .card-header {
    padding: 0;
    margin-bottom: 0;
    border-radius: 0 !important;
    background-color: #F5F5F5;
    border: 1px solid #E6E6E6;
}

/* Style the .card-header on hover (optional) */
.accordion .card .card-header:hover {
    background-color: #EFEFEF; /* Change the background color on hover */
    cursor: pointer; /* Add pointer cursor on hover (optional) */
}