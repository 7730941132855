.scrollToUp {
  background-image: url("../../assets/svgs/scroll-up.svg");
}

.bb-scroll .scroll-to-top button {
  z-index: 9999999 !important;
  transition: opacity 0.3s ease-in-out;

  position: fixed;
  bottom: 20px;
  left: 20px;
  background: #eab600;
  border-radius: 50px;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bb-scroll .scroll-to-top button:hover {
  opacity: 0.8;
}
