.googlemap{
    width: 100% !important;
    height: 480px !important;
    position: relative !important;
    border-radius: 30px !important;
    box-shadow: 0px 10px 27px 5px #80808038;
}
.googlemap div{
    border-radius: 30px !important;
}
.gmnoprint{
    display: none !important;
}
.gm-control-active{
    display: none !important;
}
.gm-style a img{
    display: none !important;
}


@media (max-width: 768px) {
    .googlemap{
        width: 100% !important;
        height: 240px !important;
        position: relative !important;
    }
       
  }